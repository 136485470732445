<template>
  <c-box>
    <template v-if="isPaying">
      <c-text font-size="xl" color="brand.500">PROGRAM</c-text>
      <hr v-chakra w="full" />

      <c-form-control mt="20px" is-required :is-invalid="$v.selectedProgramId.$invalid">
        <c-form-label>Program</c-form-label>
        <c-select h="62px" placeholder="Pilih Program" v-model="selectedProgramId">
          <option v-for="program in programs"
                  :key="program.id"
                  :value="program.id">
            {{program.name}}
          </option>
        </c-select>
      </c-form-control>
      <c-form-control mt="20px" is-required :is-invalid="$v.selectedServiceId.$invalid">
        <c-form-label>Layanan</c-form-label>
        <c-select h="62px" placeholder="Pilih Layanan" v-model="selectedServiceId">
          <option v-for="service in services"
                  :key="service.id"
                  :value="service.id">
            {{service.programsService}}
          </option>
        </c-select>
      </c-form-control>
    </template>
    <c-box v-if="isPaying" mt="30px">
      <c-text font-size="xl" color="brand.500">BIODATA</c-text>
      <hr v-chakra w="full" />
    </c-box>
    <c-flex :mt="isPaying ? ['20px'] : []" flex-dir="column">
      <!--<c-text>Foto Klien</c-text>-->
      <c-stack is-inline mt="10px" align-items="center" spacing="10px">
        <c-box rounded="full" pos="relative" h="180px" w="180px">
          <c-button
            variant="ghost"
            pos="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            bg="#00000088"
            rounded="full"
            p="0"
            :_hover="{ bg: '#00000055' }"
            @click="$refs.$inputFile.click()"
          >
            <c-image :src="require('@/assets/icon-pencil.svg')" size="55px" />
          </c-button>
          <img
            :src="photoUrl"
            v-chakra
            h="180px"
            w="180px"
            object-fit="cover"
            rounded="full"
          />
          <input
            ref="$inputFile"
            type="file"
            accept="image/*"
            v-chakra
            d="none"
            @input="onChangeFile($event)"
          />
        </c-box>
        <c-text font-size="md" font-weight="300" color="#888">
          Foto maximal 3 MB
        </c-text>
      </c-stack>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="requiredFields_.includes('firstName')"
        :is-invalid="$v.firstName.$invalid"
        w="590px"
      >
        <c-form-label>Nama Depan</c-form-label>
        <c-input h="62px" type="text" name="firstName" v-model="firstName" />
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="requiredFields_.includes('lastName')"
        :is-invalid="$v.lastName.$invalid"
        d
        w="590px"
      >
        <c-form-label>Nama Belakang</c-form-label>
        <c-input h="62px" type="text" name="lastName" v-model="lastName" />
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="requiredFields_.includes('address')"
        :is-invalid="$v.address.$invalid"
        w="590px"
      >
        <c-form-label>Alamat</c-form-label>
        <c-input h="62px" type="text" name="address" v-model="address" />
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="requiredFields_.includes('birthDate')"
        :is-invalid="$v.birthDate.$invalid"
        w="590px"
      >
        <c-form-label>Tanggal Lahir</c-form-label>
        <c-input
          h="62px"
          type="date"
          name="birthDate"
          v-model="birthDate"
          v-chakra="{
            '::-webkit-calendar-picker-indicator': {
              background: `url(${require('@/assets/icon-calendar-event.svg')}) no-repeat center`,
              cursor: 'pointer',
            },
          }"
        />
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="requiredFields_.includes('country')"
        :is-invalid="$v.country.$invalid"
        w="590px"
      >
        <c-form-label>Negara</c-form-label>
        <c-select
          h="62px"
          name="country"
          placeholder="Negara"
          v-model="country"
        >
          <option
            v-for="item in countries"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </option>
        </c-select>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="requiredFields_.includes('province')"
        :is-invalid="$v.province.$invalid"
        w="590px"
      >
        <c-form-label>Provinsi</c-form-label>
        <c-select
          h="62px"
          name="province"
          placeholder="Provinsi"
          v-model="province"
        >
          <option
            v-for="item in provinces"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </option>
        </c-select>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="requiredFields_.includes('city')"
        :is-invalid="$v.city.$invalid"
        w="590px"
      >
        <c-form-label>Kota</c-form-label>
        <c-select h="62px" name="city" placeholder="Kota" v-model="city">
          <option
            v-for="(item, index) in cities"
            :key="index"
            :value="item.value"
          >
            {{ item.label }}
          </option>
        </c-select>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="requiredFields_.includes('zipCode')"
        :is-invalid="$v.zipCode.$invalid"
        w="590px"
      >
        <c-form-label>Kode Pos</c-form-label>
        <c-input h="62px" name="zipCode" type="number" v-model="zipCode" />
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="requiredFields_.includes('phone')"
        :is-invalid="$v.phone.$invalid"
        w="590px"
      >
        <c-form-label>No. Telepon</c-form-label>
        <c-input h="62px" name="phone" type="tel" v-model="phone" />
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="requiredFields_.includes('gender')"
        :is-invalid="$v.gender.$invalid"
        w="590px"
      >
        <c-form-label>Jenis Kelamin</c-form-label>
        <c-select h="62px" name="gender" type="string" v-model="gender">
          <option value="female">Perempuan</option>
          <option value="male">Laki-Laki</option>
        </c-select>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="requiredFields_.includes('email')"
        :is-invalid="$v.email.$invalid"
        :is-disabled="isEdit"
        w="590px"
      >
        <c-form-label>Email</c-form-label>
        <c-input h="62px" name="email" type="string" v-model="email" />
      </c-form-control>
    </c-flex>
  </c-box>
</template>

<script>
import equal from "fast-deep-equal";
import requiredIf from "vuelidate/lib/validators/requiredIf";

export default {
  name: "SUClientForms",
  props: ["value", "isEdit", "requiredFields", "isPaying"],
  data() {
    return {
      firstName: null,
      lastName: null,
      photoUrl: null,
      birthPlace: null,
      phone: null,
      email: null,
      status: null,
      birthDate: null,
      address: null,
      country: null,
      province: null,
      city: null,
      zipCode: null,
      gender: null,
      // ---
      cities: [],
      countries: [],
      provinces: [],
      photoFile: null,
      // --- paying
      programs: [],
      services: [],
      selectedProgramId: null,
      selectedServiceId: null,
    };
  },
  computed: {
    requiredFields_() {
      return (
        this.requiredFields ?? [
          "firstName",
          "lastName",
          "address",
          "phone",
          "email",
          "birthDate",
          "country",
          "province",
          "city",
          "zipCode",
        ]
      );
    },
    _value() {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        photoUrl: this.photoUrl,
        photoFile: this.photoFile,
        birthPlace: this.birthPlace,
        phone: this.phone,
        email: this.email,
        status: this.status,
        birthDate: this.birthDate,
        address: this.address,
        country: this.country,
        province: this.province,
        city: this.city,
        zipCode: this.zipCode,
        gender: this.gender,
        productId: this.selectedProgramId,
        productServiceId: this.selectedServiceId,
      };
    },
  },
  watch: {
    isPaying: {
      immediate: true,
      async handler(isPaying) {
        if (isPaying === true) {
          this.programs = await this.$store.dispatch("suManagementProgram/listProgramSuperadmin")
          console.log("programs", this.programs)
        }
      },
    },
    async selectedProgramId(programId) {
      if (programId == null) return;

      let resp = await this.$store.dispatch("suManagementProgram/detailProgramSuperadmin", programId)
      this.services = resp.productServices;
    },
    photoFile(file) {
      if (this.photoUrl?.startsWith("blob") === true) {
        URL.revokeObjectURL(this.photoUrl);
        this.photoUrl = null;
      }
      let url = URL.createObjectURL(file);
      console.log("@file-changed", url);

      this.$nextTick(() => {
        this.photoUrl = url;
      });
    },
    value: {
      immediate: true,
      handler(val, old) {
        if (equal(val, old)) return;

        this.firstName = val.firstName;
        this.lastName = val.lastName;
        this.photoUrl = val.photoUrl;
        this.birthPlace = val.birthPlace;
        this.phone = val.phone;
        this.email = val.email;
        this.status = val.status;
        this.birthDate = val.birthDate;
        this.address = val.address;
        this.country = val.country;
        this.province = val.province;
        this.city = val.city;
        this.zipCode = val.zipCode;
        this.gender = val.gender;
        this.selectedProgramId = val.productId;
        this.selectedServiceId = val.productServiceId;

        if (this.country != null) {
          this.getCountries();
        }
      },
    },
    _value(val, old) {
      if (equal(val, old)) return;
      this.$emit("input", val);
    },
    country(country) {
      let countryId = this.countries.find((it) => it.name.toLowerCase() === country)?.id;
      if (countryId != null) {
        this.getStates(countryId);
      }
    },
    province(province) {
      let stateId = this.provinces.find((it) => it.name.toLowerCase() === province)?.id;
      if (stateId != null) {
        this.getCities(stateId);
      }
    },
  },
  async mounted() {
    // await this.getCountries()
  },
  methods: {
    onChangeFile($event) {
      let file = $event.target.files[0];
      if (file == null) return;

      this.photoFile = file;
    },
    async getCities(stateId) {
      let { data } = await this.axios
        .get(`/v1/general/cities?stateId=${stateId}`)
        .then((it) => it.data);
      this.cities = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        };
      });
      let f = this.cities.find(it => it.label.toLowerCase() === this.city?.toLowerCase())
      if (f != null) {
        this.city = f.label;
      }
    },
    async getStates(countryId) {
      let { data } = await this.axios
        .get(`/v1/general/states?countryId=${countryId}`)
        .then((it) => it.data);
      this.provinces = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        };
      });
      let f = this.provinces.find(it => it.label.toLowerCase() === this.province?.toLowerCase())
      if (f != null) {
        this.province = f.label;
        this.getCities(f.id);
      }
    },
    async getCountries() {
      let { data } = await this.axios
        .get("/v1/general/countries")
        .then((it) => it.data);
      this.countries = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        };
      });
      let f = this.countries.find(it => it.label.toLowerCase() === this.country?.toLowerCase())
      if (f != null) {
        this.country = f.label;
        this.getStates(f.id);
      }
    },
  },
  validations: {
    selectedProgramId: {
      required: requiredIf(function () {
        return this.isPaying
      }),
    },
    selectedServiceId: {
      required: requiredIf(function () {
        return this.isPaying
      }),
    },
    firstName: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("firstName") ?? true;
      }),
    },
    lastName: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("lastName") ?? true;
      }),
    },
    photoUrl: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("photoUrl") ?? false;
      }),
    },
    phone: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("phone") ?? true;
      }),
    },
    email: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("email") ?? true;
      }),
    },
    birthDate: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("birthDate") ?? true;
      }),
    },
    address: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("address") ?? true;
      }),
    },
    country: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("country") ?? true;
      }),
    },
    province: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("province") ?? true;
      }),
    },
    city: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("city") ?? true;
      }),
    },
    zipCode: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("zipCode") ?? true;
      }),
    },
    gender: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("firstName") ?? false;
      }),
    },
    status: {
      required: requiredIf(function () {
        return this.requiredFields?.includes("firstName") ?? false;
      }),
    },
  },
};
</script>
